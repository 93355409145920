import { skipToken } from '@reduxjs/toolkit/query'
// to be fixed in MA-857
import FroalaEditor from 'froala-editor'
import { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
// eslint-disable-next-line no-restricted-imports
import styled, { css } from 'styled-components' // to be fixed in MA-857
import { getImageUploadUrl, useGetImageUploadUrlQuery } from '@sevenrooms/core/api'
import type { VenueProfile, ExperiencesData, GroupVenuesDict } from '@sevenrooms/core/domain'
import {
  LIMITED_BUTTONS,
  EXTENDED_BUTTONS,
  MORE_TEXT_BUTTONS,
  EDIT_VIEW_BUTTONS,
  MORE_PARAGRAPH_BUTTONS,
  IMAGE_EDIT_BUTTONS,
  IMAGE_INSERT_BUTTONS,
  WITH_IMAGE_UPLOAD_BUTTONS,
} from '@sevenrooms/core/domain/constants'
import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Surface, useNavigation } from '@sevenrooms/core/navigation'
import { Toggle } from '@sevenrooms/core/ui-kit/core'
import { Window, Box, HStack } from '@sevenrooms/core/ui-kit/layout'
import { defaultConfig, FormEditor, doInsertLink, plusBoxMultiplePath } from '@sevenrooms/core/ui-kit/optional'
import { vxTheme as theme } from '@sevenrooms/core/ui-kit/theme'
import type { Venue } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { routes } from '@sevenrooms/routes'
import { campaignBuilderMessages, emailBuilderMessages } from '../../locales'
import { Offer, NoOffer } from '../Modals'
import { Menu, Reservation, Unsubscribe, CrossPromotion } from './Modals'
import type { EmailEditorDestinations, VMSEmailEditorDestinations } from './types'

interface EmailEditorProps<T extends string | null | undefined> {
  isLimitedEditor?: boolean
  imageUpload?: boolean
  disabled?: boolean
  hasDesktopView?: boolean
  field: Field<T>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config?: any
  venueProfile: VenueProfile | undefined
  experiencesData?: ExperiencesData
  crossPromotionChoices?: { id: string; label: string }[]
  groupVenuesDict?: GroupVenuesDict
  isDirty?: boolean
  isAIGenerated?: boolean
  setGoToOffers?: Function
  venue: Venue | undefined
  'data-test': string
  destination: EmailEditorDestinations
  onEditorInit?: Function
  onAutoFixClick?: Function
}

interface ResultType {
  reservation: string
  crossPromotion?: string
  unsubscribe?: string
  menu?: string
  offer?: string
}

export function EmailEditor<T extends string | null | undefined>({
  isLimitedEditor,
  imageUpload,
  disabled,
  hasDesktopView,
  field,
  config,
  venueProfile,
  experiencesData,
  crossPromotionChoices,
  groupVenuesDict,
  isDirty,
  isAIGenerated,
  setGoToOffers,
  venue,
  'data-test': dataTest,
  destination,
  onEditorInit,
  onAutoFixClick,
}: EmailEditorProps<T>) {
  const { formatMessage } = useLocales()
  const { venueSettings } = useAppContext()
  const venueKey = venue?.urlKey
  const dispatch = useDispatch()
  const nav = useNavigation()
  const uploadUrl = useGetImageUploadUrlQuery(venueKey ? { venueKey, campaignType: 'email' } : skipToken).data?.uploadUrl
  const [editor, setEditor] = useState(null)
  const [readOnlyCodeView, setReadOnlyCodeView] = useState(false)
  const readOnlyCodeViewToggle = useRef<HTMLInputElement>(null)

  if (imageUpload && editor && uploadUrl) {
    doInsertUploadUrl(editor, uploadUrl)
  }

  const doLinkInsertion = (link: { text: string; link: string; id?: string }) => {
    doInsertLink(editor, link)
  }

  const getEmbedOptions = () => {
    let result: ResultType = {
      reservation: formatMessage(campaignBuilderMessages.reservation),
    }
    if (crossPromotionChoices?.length) {
      result = {
        ...result,
        crossPromotion: formatMessage(campaignBuilderMessages.crossPromotion),
      }
    }
    result = {
      ...result,
      unsubscribe: formatMessage(emailBuilderMessages.unsubscribe),
      menu: formatMessage(campaignBuilderMessages.menu),
    }

    if (venue) {
      result = {
        ...result,
        offer: formatMessage(campaignBuilderMessages.offer),
      }
    }
    return result
  }

  FroalaEditor.DefineIcon('auto_fix', {
    NAME: 'Auto Fix',
    PATH: 'M7.5,5.6L5,7L6.4,4.5L5,2L7.5,3.4L10,2L8.6,4.5L10,7L7.5,5.6M19.5,15.4L22,14L20.6,16.5L22,19L19.5,17.6L17,19L18.4,16.5L17,14L19.5,15.4M22,2L20.6,4.5L22,7L19.5,5.6L17,7L18.4,4.5L17,2L19.5,3.4L22,2M13.34,12.78L15.78,10.34L13.66,8.22L11.22,10.66L13.34,12.78M14.37,7.29L16.71,9.63C17.1,10 17.1,10.65 16.71,11.04L5.04,22.71C4.65,23.1 4,23.1 3.63,22.71L1.29,20.37C0.9,20 0.9,19.35 1.29,18.96L12.96,7.29C13.35,6.9 14,6.9 14.37,7.29Z',
  })
  FroalaEditor.RegisterCommand('auto_fix', {
    title: 'Auto Fix',
    focus: false,
    undo: false,
    refreshAfterCallback: true,
    icon: 'auto_fix',
    callback() {
      onAutoFixClick?.()
    },
  })
  FroalaEditor.DefineIcon('embed_dropdown', { NAME: 'Embed', PATH: plusBoxMultiplePath })
  FroalaEditor.RegisterCommand('embed_dropdown', {
    title: 'Embed',
    type: 'dropdown',
    focus: false,
    undo: false,
    refreshAfterCallback: true,
    options: getEmbedOptions(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback(this: any, _: string, val: string) {
      setEditor(this)
      // eslint-disable-next-line react/no-this-in-sfc
      this.selection.save()
      switch (val) {
        case 'offer':
          if (venue) {
            nav.push(
              experiencesData?.results.length
                ? (destination as VMSEmailEditorDestinations).offerModal
                : (destination as VMSEmailEditorDestinations).noOfferModal,
              {
                params: { venueKey: venue.urlKey },
              }
            )
          }
          break
        case 'reservation':
          if (venue) {
            nav.push((destination as VMSEmailEditorDestinations).reservationModal, { params: { venueKey: venue.urlKey } })
          } else {
            nav.push(destination.reservationModal)
          }
          break
        case 'unsubscribe':
          if (venue) {
            nav.push((destination as VMSEmailEditorDestinations).unsubscribeModal, { params: { venueKey: venue.urlKey } })
          } else {
            nav.push(destination.unsubscribeModal)
          }
          break
        case 'menu':
          if (venue) {
            nav.push((destination as VMSEmailEditorDestinations).menuModal, { params: { venueKey: venue.urlKey } })
          } else {
            nav.push(destination.menuModal)
          }
          break
        case 'crossPromotion':
          if (venue) {
            nav.push((destination as VMSEmailEditorDestinations).crossPromotionModal, { params: { venueKey: venue.urlKey } })
          } else {
            nav.push(destination.crossPromotionModal)
          }
          break
        default:
      }
    },
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FroalaEditor.PLUGINS.ctaButtonIdPlugin = (editor: any) => {
    function _init() {
      editor.events.on('contentChanged', () => {
        const currentDate = new Date()
        const formattedDate = currentDate.toISOString().replace(/[^\d]/g, '').slice(0, 14)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        editor.$el.find('a').each(function (this: any, index: number) {
          let ctaButtonId = $(this).attr('id')
          if (!ctaButtonId || ctaButtonId === 'isPasted') {
            ctaButtonId = `##${[venue?.urlKey, 'CTA', formattedDate, index + 1].join('-')}`
            $(this).attr('id', ctaButtonId)
          }
        })
      })
    }
    return { _init }
  }

  let editorConfig
  let events

  if (config && config.events) {
    events = { ...config.events }
  }

  if (isLimitedEditor) {
    editorConfig = {
      height: 42,
      width: 450,
      multiLine: false,
      pastePlain: true,
      toolbarSticky: false,
      placeholderText: '',
      toolbarBottom: true,
      toolbarButtons: [...(isAIGenerated ? ['auto_fix'] : []), ...LIMITED_BUTTONS],
      enter: FroalaEditor.ENTER_BR,
      emoticonsUseImage: false,
      charCounterCount: false,
      ...config,
      events: {
        blur: onEditorBlur,
        ...events,
      },
    }
  } else {
    editorConfig = {
      ...defaultConfig,
      documentReady: hasDesktopView,
      toolbarBottom: false,
      useClasses: false,
      htmlRemoveTags: ['script', 'title'],
      enter: FroalaEditor.ENTER_DIV,
      htmlAllowedTags: [
        'a',
        'abbr',
        'address',
        'area',
        'article',
        'aside',
        'audio',
        'b',
        'base',
        'bdi',
        'bdo',
        'blockquote',
        'br',
        'button',
        'canvas',
        'caption',
        'cite',
        'code',
        'col',
        'colgroup',
        'datalist',
        'dd',
        'del',
        'details',
        'dfn',
        'dialog',
        'div',
        'dl',
        'dt',
        'em',
        'embed',
        'fieldset',
        'figcaption',
        'figure',
        'footer',
        'form',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'header',
        'hgroup',
        'hr',
        'i',
        'iframe',
        'img',
        'input',
        'ins',
        'kbd',
        'keygen',
        'label',
        'legend',
        'li',
        'link',
        'main',
        'map',
        'mark',
        'menu',
        'menuitem',
        'meter',
        'meta',
        'nav',
        'noscript',
        'object',
        'ol',
        'optgroup',
        'option',
        'output',
        'p',
        'param',
        'pre',
        'progress',
        'queue',
        'rp',
        'rt',
        'ruby',
        's',
        'samp',
        'script',
        'style',
        'section',
        'select',
        'small',
        'source',
        'span',
        'strike',
        'strong',
        'sub',
        'summary',
        'sup',
        'table',
        'tbody',
        'td',
        'textarea',
        'tfoot',
        'th',
        'thead',
        'time',
        'title',
        'tr',
        'track',
        'u',
        'ul',
        'var',
        'video',
        'wbr',
      ],
      placeholderText: '',
      imageUpload,
      imageUploadURL: uploadUrl,
      imageMaxSize: 1 * 1024 * 1024,
      imageInsertButtons: IMAGE_INSERT_BUTTONS,
      imageDefaultMargin: 0,
      imageEditButtons: IMAGE_EDIT_BUTTONS,
      toolbarButtons: {
        moreText: {
          buttons: [...(isAIGenerated ? ['auto_fix'] : []), ...MORE_TEXT_BUTTONS],
        },
        moreParagraph: {
          buttons: MORE_PARAGRAPH_BUTTONS,
          buttonsVisible: 0,
        },
        moreMisc: {
          buttons: imageUpload ? WITH_IMAGE_UPLOAD_BUTTONS : EXTENDED_BUTTONS,
          buttonsVisible: 6,
        },
        moreRich: {
          buttons: EDIT_VIEW_BUTTONS,
          buttonsVisible: 2,
          align: 'right',
        },
      },
      toolbarButtonsSM: {
        moreText: {
          buttons: [...(isAIGenerated ? ['auto_fix'] : []), ...MORE_TEXT_BUTTONS],
        },
        moreParagraph: {
          buttons: MORE_PARAGRAPH_BUTTONS,
          buttonsVisible: 0,
        },
        moreMisc: {
          buttons: imageUpload ? WITH_IMAGE_UPLOAD_BUTTONS : EXTENDED_BUTTONS,
          buttonsVisible: 6,
        },
      },
      ...config,
      events: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        initialized(this: any) {
          setEditor(this)
          clearEditor.call(this)
          onEditorInit?.()
          readOnlyCodeViewToggle.current?.addEventListener(
            'click',
            (editor => () => {
              editor.codeView.toggle()
              $('textarea.fr-code').prop('disabled', (index, value) => !value)
            })(this)
          )
        },
        blur: onEditorBlur,
        'image.beforeUpload': () => onImageBeforeUpload(dispatch, venueKey),
        'image.uploaded': onImageUploaded,
        'commands.after': onImageChangeSize,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        click(e: any) {
          e.preventDefault()
        },
        ...events,
      },
      pluginsEnabled: [...(defaultConfig.pluginsEnabled || []), 'ctaButtonIdPlugin'],
    }
  }

  return (
    <Box>
      {editor && disabled && (
        <HStack spacing="s" justifyContent="left">
          <Toggle
            name="toggle"
            data-test="email-marketing-readonly-code-view-toggle"
            ref={readOnlyCodeViewToggle}
            label={formatMessage(campaignBuilderMessages.readOnlyCodeView)}
            description={formatMessage(campaignBuilderMessages.readOnlyCodeViewDescription)}
            checked={readOnlyCodeView}
            onChange={() => {
              setReadOnlyCodeView(!readOnlyCodeView)
            }}
          />
        </HStack>
      )}
      <FormEditor
        config={editorConfig}
        field={field}
        hasDesktopView={hasDesktopView}
        venueProfile={venueProfile}
        data-test={dataTest}
        disabled={disabled}
        isLoyaltyAndPerksEnabled={venueSettings?.isLoyaltyAndPerksEnabled}
        referralProgramEnabled={venueSettings?.referralProgramEnabled}
      />

      <Surface destination={destination.unsubscribeModal}>
        <Window>
          <Unsubscribe
            venue={venue}
            insertLink={doLinkInsertion}
            destination={destination}
            closeHref={
              venue
                ? nav.closeSurfaceHref((destination as VMSEmailEditorDestinations).unsubscribeModal, {
                    params: { venueKey: venue?.urlKey },
                  })
                : nav.closeSurfaceHref(destination.unsubscribeModal)
            }
          />
        </Window>
      </Surface>

      <Surface destination={destination.menuModal}>
        <Window>
          <Menu
            venueData={{ venueProfile, venue }}
            destination={destination}
            insertLink={doLinkInsertion}
            closeHref={
              venue
                ? nav.closeSurfaceHref((destination as VMSEmailEditorDestinations).menuModal, {
                    params: { venueKey: venue?.urlKey },
                  })
                : nav.closeSurfaceHref(destination.menuModal)
            }
          />
        </Window>
      </Surface>

      <Surface destination={destination.reservationModal}>
        <Window>
          <Reservation
            insertLink={doLinkInsertion}
            destination={destination}
            venueData={{ venueProfile, venue }}
            closeHref={
              venue
                ? nav.closeSurfaceHref((destination as VMSEmailEditorDestinations).reservationModal, {
                    params: { venueKey: venue?.urlKey },
                  })
                : nav.closeSurfaceHref(destination.reservationModal)
            }
          />
        </Window>
      </Surface>

      <Surface destination={destination.crossPromotionModal}>
        <Window>
          <CrossPromotion
            venue={venue}
            insertLink={doLinkInsertion}
            destination={destination}
            closeHref={
              venue
                ? nav.closeSurfaceHref((destination as VMSEmailEditorDestinations).crossPromotionModal, {
                    params: { venueKey: venue?.urlKey },
                  })
                : nav.closeSurfaceHref(destination.crossPromotionModal)
            }
            crossPromotionChoices={crossPromotionChoices}
            groupVenuesDict={groupVenuesDict}
          />
        </Window>
      </Surface>

      {venue &&
        (experiencesData?.results.length ? (
          <Surface destination={(destination as VMSEmailEditorDestinations).offerModal}>
            <Window>
              <Offer
                venue={venue}
                experiencesData={experiencesData}
                insertLink={doLinkInsertion}
                destination={destination}
                closeHref={nav.closeSurfaceHref((destination as VMSEmailEditorDestinations).offerModal, {
                  params: { venueKey: venue?.urlKey },
                })}
                allowCustomLinkText
              />
            </Window>
          </Surface>
        ) : (
          <Surface destination={(destination as VMSEmailEditorDestinations).noOfferModal}>
            <Window>
              <NoOffer
                closeHref={nav.closeSurfaceHref((destination as VMSEmailEditorDestinations).noOfferModal, {
                  params: { venueKey: venue?.urlKey },
                })}
                confirmationModalHref={routes.manager2.marketing.emailCenter.emails.emailBuilder.cancelEmailEditModal}
                venue={venue}
                isDirty={isDirty}
                setGoToOffers={setGoToOffers}
              />
            </Window>
          </Surface>
        ))}
    </Box>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function updateCodeView(this: any) {
  if (this.codeView.isActive() && this.html.get() !== this.codeView.get()) {
    this.html.set(this.codeView.get())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function matchUnderlineColor(this: any) {
  const currentHtml = this.html.get()
  const updatedHtml = currentHtml.replace(/(<u>)(<span .*?>)(.*?)(<\/span>)(<\/u>)/gi, '$2$1$3$5$4')
  this.html.set(updatedHtml)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function onEditorBlur(this: any) {
  updateCodeView.call(this)
  matchUnderlineColor.call(this)

  if (this.codeView.isActive()) {
    this.codeView.toggle()
    this.$el[0].blur()
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function onImageUploaded(this: any, response: string) {
  const data = JSON.parse(response)
  const img = this.image.get()
  img.attr('id', data.payload.content.image_key)
  this.image.insert(data.payload.content.image_url, false, null, img, response)
  return false
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function onImageChangeSize(this: any, event: any) {
  if (event === 'imageSetSize') {
    const imgData = this.image.get()
    const { width } = imgData[0].style
    if (width.endsWith('%')) {
      if (width.slice(0, -1) > 100) {
        this.image.setSize('100%', 'auto')
      }
    } else if (width.slice(0, -2) > 600) {
      this.image.setSize('600px', 'auto')
    }
  }
}

/* eslint-disable no-param-reassign */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function doInsertUploadUrl(editor: any, uploadUrl: any) {
  editor.opts.imageUploadURL = uploadUrl
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function onImageBeforeUpload(dispatch: any, venueKey: any) {
  await dispatch(getImageUploadUrl.initiate({ venueKey, campaignType: 'email' }))
  return false
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function clearEditor(this: any) {
  const currentHtml = this.html.get()
  if (currentHtml === '') {
    this.html.set('')
  }
}

interface WrapperProps extends React.DOMAttributes<HTMLElement> {
  disable?: boolean
}

export const BodyContentWrapper = styled.div<Omit<WrapperProps, 'as'>>`
  .fr-wrapper {
    border: ${`1px solid ${theme.colors.borders}`} !important;
    border-bottom-width: 2px !important;
    overflow-y: hidden !important;
    border-radius: 0 !important;
  }
  .fr-second-toolbar {
    border: 0 !important;
  }
  .fr-view {
    ${props =>
      props.disable &&
      css`
        background-color: ${theme.colors.secondaryBackground} !important;
      `}
    padding: 12px 16px 12px 16px;
    border-radius: 4px;
    box-sizing: border-box;
    border: ${`1px solid ${theme.colors.borders}`};
    overflow: auto;
  }
  .fr-view table {
    margin-bottom: 0px;
  }
  .fr-view table td {
    padding: 0px;
  }
  .fr-view table td,
  .fr-view table th {
    border: none;
  }
`

export const LimitedEditorWrapper = styled.div<Omit<WrapperProps, 'as'>>`
  white-space: nowrap;
  .fr-view {
    line-height: 1.15 !important;
    max-height: 42px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    width: 100%;
    ${props =>
      props.disable &&
      css`
        background-color: ${theme.colors.secondaryBackground} !important;
      `}
    max-width: ${theme.sizes.fieldMaxWidth};
    padding: 12px 16px 12px 16px;
    border-radius: 4px;
    box-sizing: border-box;
    border: ${`1px solid ${theme.colors.borders}`};
    overflow: auto;
  }
  .fr-wrapper {
    border: 0 !important;
    overflow-y: hidden !important;
  }
  .fr-view::-webkit-scrollbar {
    display: none;
  }
`
