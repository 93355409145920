import type { CampaignActivityItem, OneTimeEmailCampaignType } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { Grid, BaseSection, BorderedBox, VStack } from '@sevenrooms/core/ui-kit/layout'
import { campaignCenterMessages } from '@sevenrooms/marketing'
import { getPercentageOfTotal } from '@sevenrooms/marketing/utils'
import { CampaignProgressLine } from '../CampaignProgressLine'
import { SubjectLineAndPreview } from './SubjectLineAndPreview'

export function CampaignPerformanceOpens({
  emailCampaign,
  activity,
}: {
  emailCampaign: OneTimeEmailCampaignType
  activity: CampaignActivityItem
}) {
  const { formatMessage } = useLocales()

  return (
    <VStack>
      <Grid gridTemplateColumns="205px repeat(2, minmax(0px, 1fr))" gap="lm" columnGap="lm" pb="lm">
        <VStack spacing="lm" justifyContent="space-between">
          <BaseSection
            collapsed
            padding="m"
            title={activity.opens}
            subCaption={formatMessage(campaignCenterMessages.campaignPerformanceUniqueOpens)}
          />
          <BaseSection
            collapsed
            padding="m"
            title={getPercentageOfTotal(activity.opens, activity.recipients)}
            subCaption={formatMessage(campaignCenterMessages.campaignPerformanceOpens)}
          />
        </VStack>
        <BaseSection
          maxWidth="none"
          title={formatMessage(campaignCenterMessages.campaignPerformanceOpenRateExcellentTitle)}
          subCaption={formatMessage(campaignCenterMessages.campaignPerformanceOpenRateExcellentDescription)}
        >
          <CampaignProgressLine performance={60} standard={40} />
        </BaseSection>
        <SubjectLineAndPreview emailCampaign={emailCampaign} />
      </Grid>
      <BaseSection
        maxWidth="none"
        title={formatMessage(campaignCenterMessages.campaignPerformanceOpensByClientTagTitle)}
        description={formatMessage(campaignCenterMessages.campaignPerformanceOpensByClientTagDescription)}
      >
        <BorderedBox backgroundColor="margin" height="273px" m="lm" />
      </BaseSection>
    </VStack>
  )
}
