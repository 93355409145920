import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { accessRulesMessages } from '../../../AccessRules.locales'
import { useAccessRuleContext } from '../../shared'
import { googleReserveSeatingOptionsDisplay, theForkSeatingOptionsDisplay } from '../integrationOptions'
import { SeatingAreasLocales } from '../SeatingAreas.locales'
import { SeatingAreasTestId } from '../SeatingAreas.testIds'
import type { AccessRuleReportProps } from '../../shared/AccessRuleReportProps'

export function SeatingAreasReport({ accessRule, position }: AccessRuleReportProps) {
  const { formatMessage } = useLocales()
  const { seatingAreaToTables } = useAccessRuleContext()
  const { isGoogleBookingEnabled, isTheforkIntegrationEnabled } = useAppContext().venueSettings

  const { selection, treatAsBlocked, googleReserveSeatingArea, theForkSeatingArea } = accessRule.seatingAreasAndTableCombinations

  return (
    <ReportPart caption={formatMessage(SeatingAreasLocales.title)} data-test={SeatingAreasTestId.report} position={position}>
      <ReportLine name={formatMessage(SeatingAreasLocales.multiSelectLabel)}>
        <Text>
          {selection.length === 0
            ? formatMessage(accessRulesMessages.followShift)
            : selection
                .map(({ id, label, value: { isSeatingArea } }) =>
                  isSeatingArea ? `${label} (${seatingAreaToTables.find(seatingArea => seatingArea.id === id)?.tables.length ?? 0})` : label
                )
                .join(', ')}
        </Text>
      </ReportLine>
      {treatAsBlocked && (
        <ReportLine name={formatMessage(SeatingAreasLocales.reportTreatTablesAsBlocked)}>
          <Text>{formatMessage(commonMessages.yes)}</Text>
        </ReportLine>
      )}
      {isTheforkIntegrationEnabled && (
        <ReportLine name={formatMessage(SeatingAreasLocales.theForkLabel)}>
          <Text>{theForkSeatingOptionsDisplay[theForkSeatingArea]}</Text>
        </ReportLine>
      )}
      {isGoogleBookingEnabled && (
        <ReportLine name={formatMessage(SeatingAreasLocales.googleReserveLabel)}>
          <Text>{googleReserveSeatingOptionsDisplay[googleReserveSeatingArea]}</Text>
        </ReportLine>
      )}
    </ReportPart>
  )
}
