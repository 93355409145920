/* eslint-disable camelcase */

export enum AvailableUpgrades {
  sms_marketing = 'sms_marketing',
  sms_marketing_webstore = 'sms_marketing_webstore',
  email_marketing = 'email_marketing',
  email_marketing_webstore = 'email_marketing_webstore',
}

export interface VenueSettings {
  accountID: string
  remindersSmsEnabled: boolean

  is_sizzle_enabled: boolean
  api_audience_management: boolean
  is_tax_inclusive: boolean
  booking_res_require_phone: boolean
  is_sizzle_standalone: boolean
  waitlist_widget_enabled: boolean
  show_perks_field: boolean
  booking_res_require_either_phone_email: boolean
  booking_res_require_salutation: boolean
  guestlist_enabled: boolean
  guestlist_qr_code_enabled: boolean
  default_gratuity: number
  reservation_display_order: boolean
  paylink_only: boolean
  cost_options: string[]
  features: { [key: string]: boolean }
  is_orders_integration_enabled: boolean
  landing_page_enabled: boolean
  reporting_packages: string[]
  legacy_reports: string[]
  grid_page_enabled: boolean
  ai_backwriter_enabled: boolean
  backwriter_enabled: boolean
  ff_ai_review_summary_enabled: boolean
  ff_ai_client_notes_cleanup_enabled: boolean
  is_feedback_configured: boolean
  is_looker_reporting_enabled: boolean
  is_reporting_revenue_dashboard_enabled: boolean
  payments_enabled: boolean
  default_seating_area: string
  show_availability_seatingarea_covers: boolean
  is_guest_satisfaction_standalone_enabled: boolean
  sizzle_superheroes_only: boolean
  send_email_confirmations_default: boolean
  dining_widget_enabled: boolean
  is_full_sizzle_package: boolean
  use_supafly: boolean
  is_sevenrooms_ordering_enabled: boolean
  is_guest_satisfaction_upsell_disabled: boolean
  event_widget_enabled: boolean
  enable_3d_floorplan: boolean
  payment_system: string
  tax_rate: number
  use_grid_simple_move_mode: boolean
  show_problem_res: boolean
  connected_setup_intents: boolean
  marketing_tab_enabled: boolean
  lock_contact_info_on_presence_of_this_field: string
  send_reminder_default_method: string
  send_booking_delivery_method: string
  looker_venue_user: string
  autoselect_table: boolean
  membership_enabled: boolean
  is_sevenrooms_ordering_middleware: boolean
  booking_res_require_lname: boolean
  is_custom_autotags_enabled: boolean
  is_custom_autotags_enhanced_condition_enabled: boolean
  cookie_consent_enabled: boolean
  confirmation_include_end_time: boolean
  default_service_charge: number
  booking_res_require_email: boolean
  batch_emails_enabled: boolean
  pre_built_campaigns_enabled: boolean
  stripe_key: string
  looker_group_user: null
  is_resy_reservation_manual_import_enabled: boolean
  ot_gc_enabled: boolean
  is_guest_satisfaction_enabled: boolean
  reservations_sms_enabled: boolean
  default_message_channel: string
  ordering_sms_enabled: boolean
  show_order_menu_sms: boolean
  waitlist_sms_enabled: boolean
  is_sms_marketing_enabled: boolean
  is_whatsapp_enabled: boolean
  is_google_booking_enabled: boolean
  is_thefork_integration_enabled: boolean
  is_pdr_integration_enabled: boolean
  is_flexible_fees_integration_enabled: boolean
  advanced_pdr_upgrades: boolean
  is_private_events_tab_enabled: boolean
  is_triple_seat_dinewise_enabled: boolean
  is_pdr_deposit_fee_enabled: boolean
  is_exclusive_access_perks_enabled: boolean
  is_early_access_perks_enabled: boolean
  is_buffers_enabled: boolean
  is_consumer_feature_enabled: boolean
  is_consumer_tags_enabled: boolean
  start_of_day_time: string
  is_bulk_ar_edit_enabled: boolean
  has_private_dining: boolean
  is_ivvy_enabled: boolean
  is_loyalty_and_perks_enabled: boolean
  is_loyalty_program_enabled: boolean
  direct_review_channels_enabled: boolean
  is_custom_automated_emails_enabled: boolean
  average_spend_per_cover: number
  average_spend_per_order: number
  pos_activated: boolean
  pos_enable_table_mapping_wizard: boolean // Temporary flag to gate access to Table Mapping Wizard
  is_dnd_email_editor_enabled: boolean
  is_email_marketing_deep_dive_enabled: boolean
  is_marketing_assistance_enabled: boolean
  priority_alerts_admin_enabled: boolean
  priority_alerts_tiering_admin_enabled: boolean
  priority_alerts_enabled: boolean
  priority_alerts_sms_enabled: boolean
  priority_alerts_party_size_min: number
  priority_alerts_party_size_max: number
  product_provision_package: string
  product_provision_addon_email_campaigns: boolean
  product_provision_addon_sms_marketing: boolean
  availability_lock_internal_booking_enabled: boolean
  internal_ar_booking_enabled: boolean
  internal_upsells_enabled: boolean
  multi_venue_internal_ar_booking_enabled: boolean
  show_keep_or_remove_access_rule: boolean
  res_slideout_fe_refactor_enabled: boolean
  referral_program_enabled: boolean
  sms_booking_notification_enabled: boolean
  is_flatfile_import_enabled: boolean
  topol_editor_enabled: boolean
  exclude_from_shift_pacing_enabled: boolean
  new_res_widget_enabled: boolean
  force_redirects_on_legacy_widget: boolean
  vip_chat_enabled: boolean
  available_upgrades: AvailableUpgrades[]
  ff_is_new_access_rules_settings_enabled: boolean
  durations_by_access_rule_enabled: boolean
  is_experience_templates_enabled: boolean
  is_exclusive_ar_access_enabled: boolean
  feedback_excluded_tags: string[]
  new_res_widget_private_events_enabled: boolean
  new_group_bookings_configuration_enabled: boolean
  hide_old_access_rule_editor: boolean
  default_language_code?: string
}
