import { useMemo, useCallback } from 'react'
import type { ReservationWidgetSettingsV2 } from '@sevenrooms/core/domain'
import { type Field, useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { TimeOnly } from '@sevenrooms/core/timepiece'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { Checkbox, FormMultiSelect, FormSelectRangePicker, RadioGroup, FormNumberInput, Radio, Label } from '@sevenrooms/core/ui-kit/form'
import { HStack, VStack, BaseSection, Box } from '@sevenrooms/core/ui-kit/layout'
import { Text, SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { generalMessages } from '../General/General.locales'
import { searchAvailability } from './SeachAvailability.locales'
import type { SearchAvailabilityForm } from '../ReservationSettings.zod'

export interface SearchAvailabilityProps {
  field: Field<SearchAvailabilityForm>
  data: ReservationWidgetSettingsV2
}

export function SearchAvailability({ field, data }: SearchAvailabilityProps) {
  const { formatMessage } = useLocales()
  const availabilityTimeRange = useWatch(field.prop('availabilityTimeRange'))
  const enableRequests = useWatch(field.prop('enableRequests'))
  const reservationHoldEnabled = useWatch(field.prop('reservationHoldEnabled'))
  const minutesInDay = 24 * 60
  const timesChoices = useCallback(
    (interval: number) => {
      const timeRange = []
      for (let i = 0; i < minutesInDay; i += interval) {
        const to = new TimeOnly({ hours: 0, minutes: i, seconds: 0, milliseconds: 0, timezoneOffset: 0 })
        timeRange.push({
          label: TimeOnly.fromJsDate(to.toJsDate()).formatSTime(),
          id: to.toHoursMinutesIso(),
        })
      }
      return timeRange
    },
    [minutesInDay]
  )

  const timesChoicesForRange = useMemo(() => timesChoices(30), [timesChoices])

  const timesChoicesForSpecial = useMemo(() => timesChoices(15), [timesChoices])

  const minGuestOptions = useMemo(
    (): SelectOption<number | string>[] =>
      Array.from({ length: 20 }, (_, index) => ({
        id: index + 1,
        label: String(index + 1),
      })),
    []
  )
  const maxGuestOptions = Array.from({ length: 100 }, (_, index) => ({
    id: index + 1,
    label: String(index + 1),
  }))
  return (
    <VStack spacing="lm" mt="lm">
      <BaseSection title={formatMessage(searchAvailability.searchCriteria)} padding="xl">
        <VStack spacing="l" width="100%" mt="l" pl="xl" mb="xxl" maxWidth="50%">
          <Box>
            <Box pb="s">
              <VStack spacing="xs">
                <Text fontSize="m" fontWeight="bold">
                  {formatMessage(searchAvailability.guestPickerRange)}
                </Text>
                <SecondaryText>{formatMessage(searchAvailability.guestPickerRangeDescr)}</SecondaryText>
              </VStack>
            </Box>
            <FormSelectRangePicker
              field={field.prop('guests')}
              minSelectProps={{ options: minGuestOptions, label: formatMessage(searchAvailability.minGuest) }}
              maxSelectProps={{ options: maxGuestOptions, label: formatMessage(searchAvailability.maxGuest) }}
            />
          </Box>
          <Box>
            <VStack spacing="xs">
              <Text fontSize="m" fontWeight="bold">
                {formatMessage(searchAvailability.timePickerIncrements)}
              </Text>
              <SecondaryText>{formatMessage(searchAvailability.timePickerIncrementsDescr)}</SecondaryText>
            </VStack>
            <Box pt="s">
              <VStack>
                <Box>
                  <Radio value="all" field={field.prop('availabilityTimeRange')} checked={availabilityTimeRange === 'all'}>
                    {formatMessage(searchAvailability.all)}
                  </Radio>
                </Box>
                <Box pt="s">
                  <Radio
                    value="customTimeRange"
                    field={field.prop('availabilityTimeRange')}
                    checked={availabilityTimeRange === 'customTimeRange'}
                  >
                    {formatMessage(searchAvailability.customTimeRange)}
                  </Radio>
                </Box>
                {availabilityTimeRange === 'customTimeRange' && (
                  <Box pl="xs">
                    <Box pl="lm" pt="xs">
                      <FormSelectRangePicker
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        field={field.prop('seating')}
                        minSelectProps={{ options: timesChoicesForRange, label: formatMessage(searchAvailability.firstSeating) }}
                        maxSelectProps={{ options: timesChoicesForRange, label: formatMessage(searchAvailability.lastSeating) }}
                      />
                    </Box>
                  </Box>
                )}
                <Box pt="s">
                  <Radio
                    value="specificTimeSlots"
                    field={field.prop('availabilityTimeRange')}
                    checked={availabilityTimeRange === 'specificTimeSlots'}
                  >
                    {formatMessage(searchAvailability.specificTimeSlots)}
                  </Radio>
                </Box>
              </VStack>
            </Box>

            {availabilityTimeRange === 'specificTimeSlots' && (
              <Box pl="l" pt="s">
                <FormMultiSelect
                  field={field.prop('specificTimeSlotsArray')}
                  options={timesChoicesForSpecial}
                  useCheckboxItems
                  searchable
                  placeholder={formatMessage(searchAvailability.selectTimeSlot)}
                />
              </Box>
            )}
          </Box>
          <Box>
            <VStack spacing="xs">
              <Text fontSize="m" fontWeight="bold">
                {formatMessage(searchAvailability.datePickerCalendarLength)}
              </Text>
              <SecondaryText>{formatMessage(searchAvailability.datePickerCalendarLengthDescr)}</SecondaryText>
            </VStack>
            <Box width="46%" pt="s">
              <FormNumberInput
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                field={field.prop('maxDaysOut')}
                greaterThanZero
                required
              />
            </Box>
          </Box>
        </VStack>
      </BaseSection>

      <BaseSection title={formatMessage(searchAvailability.reservationRequests)} padding="xl">
        <VStack spacing="l" mt="l" pl="xl" mb="xxl">
          <HStack>
            <Checkbox field={field.prop('enableRequests')} disabled={data.searchAvailability.priorityAlertsEnabled}>
              <VStack>
                <Box pt="xs" pb="xs">
                  <Text fontWeight="bold">{formatMessage(searchAvailability.priorityAlertRequests)}</Text>
                </Box>
                <SecondaryText>
                  {formatMessage(
                    data.searchAvailability.priorityAlertsEnabled
                      ? searchAvailability.priorityAlertRequestsDescr
                      : searchAvailability.reservationRequestsDescr
                  )}
                </SecondaryText>
                {enableRequests && (
                  <Box pt="s">
                    <RadioGroup
                      field={field.prop('enableRequestsType')}
                      data-test="enableRequestsType"
                      choices={[
                        { label: formatMessage(searchAvailability.shiftLabel), value: 'searched_time' },
                        {
                          label: formatMessage(searchAvailability.accessRulesLabel),
                          info: <>{formatMessage(searchAvailability.accessRulesTooltip)}</>,
                          value: 'active_booking_window',
                        },
                      ]}
                    />
                  </Box>
                )}
              </VStack>
            </Checkbox>
          </HStack>
          {reservationHoldEnabled && (
            <Label
              primary={<Text textStyle="body1Bold">{formatMessage(generalMessages.brandSettingsReservationHoldTimeMinsLabel)}</Text>}
              secondary={formatMessage(generalMessages.brandSettingReservationHoldTimeMinsDescription, { br: <br /> })}
            >
              <Box width="22%">
                <FormNumberInput field={field.prop('reservationHoldTimeMins')} greaterThanZero fullWidth data-test="sr-hold-time-mins" />
              </Box>
            </Label>
          )}
          <VStack>
            <Box pt="xs" pb="xs">
              <Text fontWeight="bold">{formatMessage(searchAvailability.formatOfRequestingTIme)}</Text>
            </Box>
            <Box pt="s">
              <RadioGroup
                field={field.prop('formatOfRequestingTime')}
                choices={[
                  {
                    label: (
                      <VStack spacing="xs">
                        <Text>{formatMessage(searchAvailability.singleTimeFrame)}</Text>
                        <SecondaryText>{formatMessage(searchAvailability.singleTimeFrameEx)}</SecondaryText>
                      </VStack>
                    ),
                    value: 'single',
                  },
                  {
                    label: (
                      <VStack spacing="xs">
                        <Text>{formatMessage(searchAvailability.timeRange)}</Text>
                        <SecondaryText>{formatMessage(searchAvailability.timeRangeEx)}</SecondaryText>
                      </VStack>
                    ),
                    value: 'time_range',
                  },
                ]}
              />
            </Box>
          </VStack>
        </VStack>
      </BaseSection>
      <BaseSection title={formatMessage(searchAvailability.availabilityConfigurations)} padding="xl">
        <VStack spacing="l" mt="l" pl="xl" mb="xxl">
          <HStack>
            <Checkbox field={field.prop('isFeesInPriceDisplayed')}>
              <VStack>
                <Box pt="xs" pb="xs">
                  <Text fontWeight="bold">{formatMessage(searchAvailability.displayAdditionalFeesInPrice)}</Text>
                </Box>
                <SecondaryText>{formatMessage(searchAvailability.displayAdditionalFeesInPriceDescr)}</SecondaryText>
              </VStack>
            </Checkbox>
          </HStack>
        </VStack>
      </BaseSection>
    </VStack>
  )
}
