import type { VenueClassEnum, VenueSettingsOptions } from '@sevenrooms/core/domain'
import { useWatch, type Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Label, TextArea } from '@sevenrooms/core/ui-kit/form'
import { Box, DividerLine, SectionHeader, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Anchor, Text } from '@sevenrooms/core/ui-kit/typography'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { SectionBox, ToggleToggler } from '../components'
import { MagicWand } from '../components/MagicWand'
import { backwriterSettingsMessages } from './Backwriter.locales'
import type { FormValues } from './Backwriter.zod'

export interface BackwriterProps {
  field: Field<FormValues>
  options: VenueSettingsOptions
  venueClass: VenueClassEnum
}

export function Backwriter({ field }: BackwriterProps) {
  const { formatMessage } = useLocales()
  const { venueSettings } = useAppContext()

  const enabled = useWatch(field.prop('enabled'))
  const smsEnabled = venueSettings.waitlistSmsEnabled || venueSettings.vipChatEnabled || venueSettings.reservationsSmsEnabled

  return (
    <VStack spacing="lm" mt="lm">
      <SectionBox
        title={
          <div data-test="backwriter-general-settings-header">
            {formatMessage(backwriterSettingsMessages.header, {
              icon: <MagicWand />,
            })}
          </div>
        }
        description={
          <VStack>
            <Text color="description">
              {formatMessage(backwriterSettingsMessages.enabledDescription, {
                a: (chunks: string[]) => (
                  <Anchor href="https://help.sevenrooms.com/hc/en-us/articles/25839385042203-AI-Responses-Beta">{chunks}</Anchor>
                ),
              })}
            </Text>
          </VStack>
        }
      >
        <VStack spacing="m">
          <Box maxWidth="100px">
            <ToggleToggler
              title={formatMessage(backwriterSettingsMessages.enabledTitle)}
              field={field.prop('enabled')}
              data-test="backwriterSettingEnabled"
            />
          </Box>
          {enabled && (
            <>
              <DividerLine />
              <Box>
                <SectionHeader
                  title={<div data-test="backwriter-email-header">{formatMessage(backwriterSettingsMessages.emailResponseHeader)}</div>}
                />
                <Box mt="m">
                  <Label primary={formatMessage(backwriterSettingsMessages.emailGeneralTuning)}>
                    <TextArea
                      field={field.prop('promptSettings.emailGeneralTuning')}
                      rows={4}
                      resize="none"
                      placeholder={formatMessage(backwriterSettingsMessages.emailGeneralTuningPlaceholder)}
                      data-test="email-general-tuning-textarea"
                    />
                  </Label>
                </Box>
                <Box mt="s">
                  <Label primary={formatMessage(backwriterSettingsMessages.emailSignature)}>
                    <TextArea
                      field={field.prop('promptSettings.emailSignature')}
                      rows={4}
                      resize="none"
                      placeholder={formatMessage(backwriterSettingsMessages.emailSignaturePlaceholder)}
                      data-test="email-signature-textarea"
                    />
                  </Label>
                </Box>
              </Box>
              {smsEnabled && (
                <>
                  <DividerLine />
                  <Box>
                    <SectionHeader
                      title={<div data-test="backwriter-sms-header">{formatMessage(backwriterSettingsMessages.smsHeader)}</div>}
                    />
                    <Box mt="m">
                      <Label primary={formatMessage(backwriterSettingsMessages.smsGeneralTuning)}>
                        <TextArea
                          field={field.prop('promptSettings.smsGeneralTuning')}
                          rows={4}
                          resize="none"
                          placeholder={formatMessage(backwriterSettingsMessages.smsGeneralTuningPlaceholder)}
                          data-test="sms-general-tuning-textarea"
                        />
                      </Label>
                    </Box>
                  </Box>
                </>
              )}
              <DividerLine />
              <Box>
                <SectionHeader
                  title={<div data-test="backwriter-reviews-header">{formatMessage(backwriterSettingsMessages.reviewsHeader)}</div>}
                />
                <Box mt="m">
                  <Label primary={formatMessage(backwriterSettingsMessages.reviewsGeneralTuning)}>
                    <TextArea
                      field={field.prop('promptSettings.reviewsGeneralTuning')}
                      rows={4}
                      resize="none"
                      placeholder={formatMessage(backwriterSettingsMessages.reviewsGeneralTuningPlaceholder)}
                      data-test="reviews-general-tuning-textarea"
                    />
                  </Label>
                </Box>
              </Box>
            </>
          )}
        </VStack>
      </SectionBox>
      {venueSettings.ffAiClientNotesCleanupEnabled && (
        <>
          <SectionBox
            halfsize
            title={
              <div data-test="backwriter-client-notes-settings-header">
                {formatMessage(backwriterSettingsMessages.clientNotesHeader, {
                  icon: <MagicWand />,
                })}
              </div>
            }
            subCaption={formatMessage(backwriterSettingsMessages.clientNotesDescription, {
              a: (chunks: string[]) => (
                <Anchor href="https://help.sevenrooms.com/hc/en-us/articles/31109298086555-AI-Note-Polish-Beta">{chunks}</Anchor>
              ),
            })}
          >
            <Box mt="s">
              <Label primary={<>{formatMessage(backwriterSettingsMessages.clientNotesGeneralTuning)}</>}>
                <TextArea
                  field={field.prop('promptSettings.clientNotesGeneralTuning')}
                  rows={32}
                  resize="none"
                  data-test="client-notes-tuning-textarea"
                />
              </Label>
            </Box>
          </SectionBox>
        </>
      )}
    </VStack>
  )
}
