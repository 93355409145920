import { type PropsWithChildren, type ReactElement, useCallback, useMemo, useState } from 'react'
import { useCreateExperienceMutation, useCreateAccessRuleMutation } from '@sevenrooms/core/api'
import { useForm } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Box, notify } from '@sevenrooms/core/ui-kit/layout'
import { formatDateOnly } from '@sevenrooms/mgr-access-rules-slideout/AccessRulesSlideoutForm'
import { getShiftSeatingAreas } from '@sevenrooms/mgr-access-rules-slideout/components/SeatingAreas/SeatingAreas.zod'
import { useAccessRuleContext } from '@sevenrooms/mgr-access-rules-slideout/components/shared'
import { toAccessRuleInput } from '@sevenrooms/mgr-access-rules-slideout/toAccessRuleInput'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { Modal } from '@sevenrooms/react-components/components/Modal'
import { routes } from '@sevenrooms/routes'
import { StepperFlow, type Steps } from '../StepperFlow'
import { Availability } from './Availability'
import {
  type Availabilities,
  formToExperience,
  useCreateExperienceFromTemplateForm,
  useDefaultValues,
} from './CreateExperienceFromTemplateForm.zod'
import { FinalPage } from './FinalPage'
import { messages } from './locales/experienceStepperFlow.locales'
import { NewAccessRuleCustomization } from './NewAccessRuleCustomization'
import { NewAccessRulePayment } from './NewAccessRulePayment'
import { NewAccessRuleScheduling } from './NewAccessRuleScheduling'
import { OfferDetails } from './OfferDetails'
import type { ExperienceTemplateProps } from '../ExperienceTemplatesGallery'

export function ExperienceStepperFlow({
  templateData,
  venueKey,
  venueName,
  venueId,
}: {
  templateData: ExperienceTemplateProps
  venueKey: string
  venueName: string
  venueId: string
}) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venueSettings, venueCurrencyCode } = useAppContext()
  const accessRuleContext = useAccessRuleContext()

  const createOfferFromTemplateSchema = useCreateExperienceFromTemplateForm()
  const defaultValues = useDefaultValues(templateData, venueName, venueCurrencyCode, venueSettings.startOfDayTime, accessRuleContext)
  const shiftSeatingAreas = useMemo(
    () => getShiftSeatingAreas(accessRuleContext.allShifts, accessRuleContext.seatingAreaToTables),
    [accessRuleContext.allShifts, accessRuleContext.seatingAreaToTables]
  )

  const [showNextButton, setShowNextButton] = useState(true)
  const [useSaveButton, setUseSaveButton] = useState(false)
  const [experienceFlowCompleted, setExperienceFlowCompleted] = useState(false)
  const [createdExperience, setCreatedExperience] = useState(undefined)
  const [isCreateExperienceLoading, setIsCreateExperienceLoading] = useState(false)
  const [createExperience] = useCreateExperienceMutation()
  const [createAccessRule] = useCreateAccessRuleMutation()

  const [selectedAvailabilityOption, setSelectedAvailabilityOption] = useState<Availabilities | undefined>(undefined)
  const [isAccessRuleCustomizationInitialPart, setIsAccessRuleCustomizationInitialPart] = useState(true)
  const [flowStep, setFlowStep] = useState<number | undefined>(undefined)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const form = useForm(createOfferFromTemplateSchema, { defaultValues, mode: 'onChange' })

  const handleOnSubmit = useCallback(async () => {
    const { getValues } = form
    let values = getValues()
    if (selectedAvailabilityOption === 'createNewAccessRule') {
      const prepData = toAccessRuleInput(formatDateOnly(new Date()), venueId, false, false, accessRuleContext)
      const accessRuleData = prepData(values.accessRule)
      const accessRule = await createAccessRule({ data: accessRuleData, args: { venueId } }).unwrap()
      values = { ...values, linkedAccessRuleIds: [accessRule.id] }
    }
    const createExperienceData = await formToExperience({
      formData: values,
      onError: () => {
        setIsCreateExperienceLoading(false)
        notify({
          content: formatMessage(messages.experienceAPIError),
          type: 'error',
        })
      },
    })
    return createExperience({
      experience: createExperienceData,
      venueId,
    })
  }, [form, accessRuleContext, selectedAvailabilityOption, createExperience, venueId, createAccessRule, formatMessage])

  const onCancel = () => {
    nav.push(routes.manager2.marketing.offers, { params: { venueKey } })
  }

  const onFlowSubmit = () => {
    setIsCreateExperienceLoading(true)
    handleOnSubmit().then(result => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setCreatedExperience(result?.data || undefined)
      setExperienceFlowCompleted(true)
    })
  }

  const steps: Steps = {
    offerDetails: {
      title: formatMessage(messages.stepOfferDetails),
      component: <OfferDetails form={form} setShowNextButton={setShowNextButton} />,
      isValid: () => form.trigger(['name', 'description', 'heroImage']),
    },
    availability: {
      title: formatMessage(messages.stepAvailability),
      component: (
        <Availability
          form={form}
          venueId={venueId}
          setShowNextButton={setShowNextButton}
          setFlowCompleted={setExperienceFlowCompleted}
          selectedAvailabilityOption={selectedAvailabilityOption}
          setSelectedAvailabilityOption={setSelectedAvailabilityOption}
        />
      ),
      isValid: () => true,
      handleBack: selectedAvailabilityOption ? () => setSelectedAvailabilityOption(undefined) : undefined,
      replaceHandleBack: true,
    },
    scheduling: {
      title: formatMessage(messages.stepScheduling),
      component: <NewAccessRuleScheduling form={form} allShifts={accessRuleContext.allShifts} />,
      isValid: () =>
        form.trigger(['accessRule.schedule.dateRange', 'accessRule.schedule.selectedDays', 'accessRule.schedule.shiftCategories']),
      handleBack: () => setSelectedAvailabilityOption(undefined),
    },
    payment: {
      title: formatMessage(messages.stepPayment),
      component: <NewAccessRulePayment form={form} />,
      isValid: () =>
        form.trigger([
          'accessRule.paymentPolicy.paymentRule',
          'accessRule.paymentPolicy.bookingCharge.amount',
          'accessRule.paymentPolicy.partySizeMin',
          'accessRule.paymentPolicy.charges.gratuityPercent',
          'accessRule.paymentPolicy.charges.serviceChargePercent',
          'accessRule.paymentPolicy.charges.taxId',
        ]),
    },
    customization: {
      title: formatMessage(messages.stepCustomization),
      component: (
        <NewAccessRuleCustomization
          form={form}
          shiftSeatingAreas={shiftSeatingAreas}
          setFlowCompleted={setExperienceFlowCompleted}
          isAccessRuleCustomizationInitialPart={isAccessRuleCustomizationInitialPart}
          setIsAccessRuleCustomizationInitialPart={setIsAccessRuleCustomizationInitialPart}
          setFlowStep={setFlowStep}
          setUseSaveButton={setUseSaveButton}
        />
      ),
      isValid: () => (isAccessRuleCustomizationInitialPart ? form.trigger(['accessRule.partySize.min', 'accessRule.partySize.max']) : true),
      handleBack: !isAccessRuleCustomizationInitialPart ? () => setIsAccessRuleCustomizationInitialPart(true) : undefined,
      replaceHandleBack: true,
      handleNext: isAccessRuleCustomizationInitialPart ? () => setIsAccessRuleCustomizationInitialPart(false) : undefined,
      replaceHandleNext: true,
    },
  }

  return (
    <OffersStepperFlowWrapper key="offers-stepper-flow-window">
      {experienceFlowCompleted && createdExperience ? (
        <FinalPage venueUrlKey={venueKey} experience={createdExperience} form={form} />
      ) : (
        <StepperFlow
          form={form}
          steps={steps}
          onCancel={onCancel}
          onFlowSubmit={onFlowSubmit}
          isFlowSubmitLoading={isCreateExperienceLoading}
          isFlowCompleted={experienceFlowCompleted}
          setFlowCompleted={setExperienceFlowCompleted}
          fullFooterMenu={showNextButton}
          flowStep={flowStep}
          setFlowStep={setFlowStep}
          useSaveButton={useSaveButton}
          setUseSaveButton={setUseSaveButton}
        />
      )}
    </OffersStepperFlowWrapper>
  )
}

function OffersStepperFlowWrapper({ children }: { children: PropsWithChildren<ReactElement> }) {
  return (
    <Modal open sx={{ overflow: 'auto', height: '100%', width: '100%' }} data-test="offers-stepper-flow-window">
      <Box width="100vw" height="100vh" pt="m" backgroundColor="inputBackground" overflow="auto" data-test="offers-stepper-flow-window">
        {children}
      </Box>
    </Modal>
  )
}
