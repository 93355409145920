import { useLocales } from '@sevenrooms/core/locales'
import { Banner } from '@sevenrooms/core/ui-kit/layout'
import { accessRulesMessages } from '../../AccessRules.locales'

export function DurationWidgetRequired() {
  const { formatMessage } = useLocales()
  const learnMoreLink = 'https://help.sevenrooms.com/hc/en-us/articles/360032170091-Using-Durations-with-the-Reservation-Widget'

  return (
    <Banner
      title={formatMessage(accessRulesMessages.durationWidgetRequiredTitle)}
      description={formatMessage(accessRulesMessages.durationWidgetRequiredBody)}
      type="warning"
      action={formatMessage(accessRulesMessages.learnMore)}
      onAction={() => window.open(learnMoreLink, '_blank')}
    />
  )
}
