import TodayIcon from '@mui/icons-material/Today'
import { useMemo } from 'react'
import { DateOnly } from '@sevenrooms/core/timepiece'
import type { AccessRuleProps } from '@sevenrooms/mgr-access-rules-slideout/utils/accessRulesCalendarUtils'
import { assignRandomInternalDisplayColor } from '@sevenrooms/mgr-access-rules-slideout/utils/displayColorUtils'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { orderToTime } from '../../../../../application/site/static/app/common/TimeUtil'
import { useAccessRuleSlideout } from '../../contexts/AccessRuleSlideoutProvider'
import { repeatingDiagonalGradient } from '../../utils/styleUtils'

export interface AccessRuleBlockProps extends AccessRuleProps {
  column: number
  overlap: boolean
  overlapIndex: number
  overlapCount: number
  venueStartOfDayHour: number
  sortOrderOffset: number
  hover: boolean
  setHover: (hover: boolean) => void
}

function formatSortOrder(venueStartOfDayHour: number, sortOrder: number) {
  return orderToTime(venueStartOfDayHour, sortOrder).toDate().toLocaleTimeString([], { hour: 'numeric', minute: 'numeric' })
}

export function AccessRuleBlock(props: AccessRuleBlockProps) {
  const showAccessRuleSlideout = useAccessRuleSlideout()
  const onClick = () => showAccessRuleSlideout({ accessRule: props.rule, startDate: props.date.toJsDate(), mode: undefined })

  if (props.specificTimeSortOrders) {
    return <AccessRuleSpecificTimesDisplay {...props} onClick={onClick} stripes={false} />
  }

  const inShift = props.shifts.some(
    x =>
      x.startTimeSortOrder && props.startSortOrder >= x.startTimeSortOrder && x.endTimeSortOrder && props.endSortOrder <= x.endTimeSortOrder
  )

  return (
    <AccessRuleBlockDisplay {...props} onClick={onClick} stripes={!inShift}>
      <Typography
        fontSize={10}
        lineHeight="12px"
        pl="2px"
        padding={props.endSortOrder - props.startSortOrder > 1 || props.hover ? '2px' : undefined}
        overflow="hidden"
        textOverflow="ellipsis"
        sx={{ overflowWrap: props.hover ? 'break-word' : undefined }}
      >
        {props.rule.isOverride && <OverrideIcon />}
        {props.rule.name}
        <br />
        {formatSortOrder(props.venueStartOfDayHour, props.startSortOrder)} -{' '}
        {formatSortOrder(props.venueStartOfDayHour, props.endSortOrder)}
      </Typography>
    </AccessRuleBlockDisplay>
  )
}

interface AccessRuleBlockDisplay extends React.PropsWithChildren<AccessRuleBlockProps> {
  onClick: Function
  stripes: boolean
}

function AccessRuleSpecificTimesDisplay(props: AccessRuleBlockDisplay) {
  const specificTimesData = props.specificTimeSortOrders?.map(sortOrder => {
    const inShift = props.shifts.some(
      x => x.startTimeSortOrder && sortOrder >= x.startTimeSortOrder && x.endTimeSortOrder && sortOrder <= x.endTimeSortOrder
    )

    return { sortOrder, inShift }
  })

  if (props.hover) {
    return (
      <AccessRuleBlockDisplay
        key={`specific-times-block-${props.rule.id}-${props.date.toIso()}-${props.startSortOrder}`}
        {...props}
        startSortOrder={props.startSortOrder}
        endSortOrder={props.endSortOrder}
        stripes={specificTimesData?.some(({ inShift }) => !inShift) ?? false}
      >
        <Typography
          fontSize={10}
          lineHeight="12px"
          pl="2px"
          overflow="hidden"
          textOverflow="ellipsis"
          sx={{ overflowWrap: props.hover ? 'break-word' : undefined }}
        >
          {props.rule.isOverride && <OverrideIcon />}
          {props.rule.name}
          <br />
          {props.specificTimeSortOrders?.map(sortOrder => formatSortOrder(props.venueStartOfDayHour, sortOrder)).join(', ')}
        </Typography>
      </AccessRuleBlockDisplay>
    )
  }

  return (
    <>
      {specificTimesData?.map(({ sortOrder, inShift }) => (
        <AccessRuleBlockDisplay
          key={`specific-times-block-${props.rule.id}-${props.date.toIso()}-${sortOrder}`}
          {...props}
          startSortOrder={sortOrder}
          endSortOrder={sortOrder}
          stripes={!inShift}
        >
          <Typography
            fontSize={10}
            lineHeight="12px"
            pl="2px"
            overflow="hidden"
            textOverflow="ellipsis"
            sx={{ overflowWrap: props.hover ? 'break-word' : undefined }}
          >
            {props.rule.isOverride && <OverrideIcon />}
            {props.rule.name} - {formatSortOrder(props.venueStartOfDayHour, sortOrder)}
          </Typography>
        </AccessRuleBlockDisplay>
      ))}
    </>
  )
}

function AccessRuleBlockDisplay({
  children,
  date,
  column,
  startSortOrder,
  endSortOrder,
  overlapIndex,
  overlapCount,
  overlap,
  sortOrderOffset,
  onClick,
  hover,
  setHover,
  stripes,
  rule,
}: AccessRuleBlockDisplay) {
  const margin = 0
  const rightMargin = 15
  const width = overlap
    ? `min(calc(100% - ${rightMargin}px), max(calc(100% / ${overlapCount}), 50px))`
    : `calc((100% - ${rightMargin}px) / ${overlapCount})`
  const leftMarginEach = overlapCount > 1 ? `calc((100% - ${width} - ${rightMargin}px) / ${overlapCount - 1})` : 0
  const marginLeft = overlap ? `calc(${leftMarginEach} * ${overlapIndex})` : `calc(${width} * ${overlapIndex})`

  const displayColor = useMemo(() => rule.internalDisplayColor ?? assignRandomInternalDisplayColor(), [rule.internalDisplayColor])

  return (
    <Box
      data-id={rule.id}
      data-persistent-id={rule.persistentId}
      sx={{
        gridColumn: `${column} col-start / ${column} col-end`,
        // +1 for css grid 1 based indexing and +4 for to skip visual buffer row
        gridRow: `${startSortOrder - sortOrderOffset + 5} row-start / ${endSortOrder - sortOrderOffset + 5} row-start`,
        zIndex: hover ? 10000 : 10 * (startSortOrder + 1) + overlapIndex,
        width,
        marginLeft,
        height: `calc(100% - ${margin * 2}px)`,
        position: 'relative',
      }}
    >
      <Box
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => {
          onClick()
        }}
        boxShadow={hover ? 1 : undefined}
        sx={{
          position: 'absolute',
          height: hover ? 'fit-content' : '100%',
          minHeight: 'max(12px, 100%)',
          width: '100%',
          marginTop: `${margin}px`,
          marginBottom: `${margin}px`,
          borderWidth: '1px',
          borderRadius: '3px',
          borderStyle: 'solid',
          boxSizing: 'border-box',
          overflow: 'hidden',
          filter: date.isLessThan(DateOnly.fromDate(new Date())) ? 'saturate(.5)' : undefined,
          borderColor: `hsl(from ${displayColor} h s calc(l - 20))`,
          ...(stripes ? repeatingDiagonalGradient(displayColor) : {}),
          backgroundColor: displayColor,
          cursor: 'pointer',
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

function OverrideIcon() {
  return <TodayIcon fontSize="inherit" sx={{ verticalAlign: 'middle', paddingRight: 1 }} />
}
