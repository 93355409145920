import { useMemo, useCallback } from 'react'
import type { OneTimeSMSCampaignType } from '@sevenrooms/core/api'
import type { VenueProfile } from '@sevenrooms/core/domain'
import { useForm, useWatchMany } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Surface, useDestination, useNavigation } from '@sevenrooms/core/navigation'
import { Button, Form } from '@sevenrooms/core/ui-kit/form'
import { Window, Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Audience, type AudienceFormField, smsBuilderMessages, htmlToSMS } from '@sevenrooms/marketing'
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { OneTimeSendingSchedule, type OneTimeSendingScheduleFormField } from '@sevenrooms/mgr-marketing-one-time-email-center'
import { SMSContentFromScratch } from '@sevenrooms/mgr-marketing-one-time-sms-center/OneTimeSMSCampaign/SMSContentFromScratch/SMSContentFromScratch'
import { SMSDetails } from '@sevenrooms/mgr-marketing-one-time-sms-center/SMSDetails'
import { routes } from '@sevenrooms/routes'
import { getOneTimeSMSBuilderFormDefaultValues, useOneTimeSMSCampaignForm } from '../OneTimeSMSCampaign/OneTimeSMSCampaignForm.zod'
import { SendTestSMSModal } from '../OneTimeSMSCampaign/SendTestSMSModal'

export interface OneTimeSMSCampaignViewerFormProps {
  smsCampaign: OneTimeSMSCampaignType
  venueProfile: VenueProfile
}

export function OneTimeSMSCampaignViewerForm({ smsCampaign, venueProfile }: OneTimeSMSCampaignViewerFormProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { params: editParams } = useDestination(routes.manager2.marketing.oneTimeSMSCenter.viewOneTimeSMS)
  const { campaignId } = editParams

  const { venue, venueId } = useVenueContext()

  const defaultValues = useMemo(() => getOneTimeSMSBuilderFormDefaultValues(smsCampaign ?? undefined), [smsCampaign])
  const form = useForm(useOneTimeSMSCampaignForm(), {
    defaultValues,
  })
  const { field } = form

  const [messageBody, messageMedia] = useWatchMany(field, ['messageBody', 'messageMedia'])
  const formattedMessageBody = useMemo(() => (messageBody ? htmlToSMS(messageBody) : messageBody), [messageBody])
  const cancelModalCloseHref = nav.href(routes.manager2.marketing.oneTimeSMSCenter.viewOneTimeSMS, {
    params: { venueKey: venue.urlKey, campaignId: smsCampaign.id },
    queryMode: 'clear',
  })

  const onCampaignDuplicateClick = useCallback(
    (campaignId: string) => {
      if (venue) {
        nav.push(routes.manager2.marketing.oneTimeSMSCenter.createOneTimeSMS, {
          params: { venueKey: venue.urlKey },
          query: { campaignToDuplicateId: campaignId, parentTemplateId: '' },
        })
      }
    },
    [nav, venue]
  )

  if (!venue) {
    return null
  }

  return (
    <>
      <SettingsPageMeta venue={venue?.name} title={formatMessage(smsBuilderMessages.viewSMSCampaign)} />
      <Form {...form} onSubmit={() => {}} onInvalid={() => {}}>
        <SettingsPageContent
          title={smsCampaign ? `${smsCampaign.campaignName}` : ''}
          actions={
            <HStack spacing="s">
              <Button
                variant="tertiary"
                data-test="one-time-sms-builder-cancel-button"
                onClick={() => {
                  window.location.assign(
                    nav.href(routes.manager2.marketing.oneTimeSMSCenter, { params: { venueKey: venue.urlKey } }).split('?')[0] || ''
                  )
                }}
              >
                {formatMessage(commonMessages.cancel)}
              </Button>
              <Button type="submit" data-test="one-time-sms-builder-duplicate-button" onClick={() => onCampaignDuplicateClick(campaignId)}>
                {formatMessage(smsBuilderMessages.duplicate)}
              </Button>
            </HStack>
          }
        >
          <Box p="lm" width="100%">
            <VStack spacing="lm">
              <SMSDetails disabled autoFocus campaign={smsCampaign} fields={field} data-test="one-time-sms-builder-details-section" />
              <Audience
                campaignContent={smsCampaign}
                venueProfile={venueProfile}
                field={field as unknown as AudienceFormField}
                venue={venue}
                isOneTimeCampaign
                disabled
                messages={smsBuilderMessages}
                data-test="one-time-sms-builder-audience-section"
              />
              <OneTimeSendingSchedule field={field as unknown as OneTimeSendingScheduleFormField} disabled isSMSBuilder />
              <SMSContentFromScratch disabled fields={field} data-test="one-time-sms-builder-content-section" venueProfile={venueProfile} />
            </VStack>
          </Box>
        </SettingsPageContent>
        <Surface destination={routes.manager2.marketing.oneTimeSMSCenter.sendTestSMSModal}>
          <Window>
            <SendTestSMSModal
              venueId={venueId}
              messageBody={formattedMessageBody}
              messageMedia={messageMedia}
              closeHref={cancelModalCloseHref}
            />
          </Window>
        </Surface>
      </Form>
    </>
  )
}
