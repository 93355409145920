import { useNavigation } from '@sevenrooms/core/navigation'
import type { DateOnly } from '@sevenrooms/core/timepiece/index'
import { routes } from '@sevenrooms/routes'
import { useVenueSettingsContext } from './useVenueSettingsContext'

function getOldAccessRuleUrl(venueKey: string, date: DateOnly, accessRuleId: string) {
  const dInfo = date.getInfo()
  const formattedDate = `${dInfo.month + 1}/${dInfo.day}/${dInfo.year}`
  return `/manager/${venueKey}/manage/capacity/accessrules?selected_access_rule_id=${accessRuleId}&date=${formattedDate}`
}

export function useGetAccessRuleUrl() {
  const nav = useNavigation()
  const { venueSettings } = useVenueSettingsContext()
  if (venueSettings?.ff_is_new_access_rules_settings_enabled) {
    return (venueKey: string, date: DateOnly, accessRuleId: string) =>
      nav
        ? nav.href(routes.manager2.settings.availability.accessRulesSettings, {
            params: { venueKey },
            query: { date: date.toIso(), selected_access_rule_id: accessRuleId },
          })
        : `/manager2/${venueKey}/settings/availability/accessrules?date=${date.toIso()}&selected_access_rule_id=${accessRuleId}`
  }

  return getOldAccessRuleUrl
}
