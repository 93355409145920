import { useMemo, useState } from 'react'
import { useFieldArray, useForm } from '@sevenrooms/core/form'
import { useLocales, commonMessages } from '@sevenrooms/core/locales'
import { Button, Form, FormInput } from '@sevenrooms/core/ui-kit/form'
import { VStack, HStack, BaseSection, Loader, Window, notify } from '@sevenrooms/core/ui-kit/layout'
import { SettingsPageMeta, SettingsPageContent, useVenueContext } from '@sevenrooms/mgr-core'
import { DeleteShiftPeriod } from './DeleteShiftPeriod'
import { MealRangeSelector } from './MealRangeSelector'
import { ShriftReportingPeriodSettingsMessages } from './ShiftReportingPeriods.locales'
import { useShiftReportPeriodsSettingsSchema, type FormValues } from './ShiftReportingPeriods.zod'
import { useGetVenueShiftReportingPeriodsQuery, useUpdateVenueShiftReportingPeriodsMutation } from './ShiftReportingPeriodsApi'

const DEFAULT_REPORTING_PERIOD = {
  name: '',
  isDefault: false,
  breakfast: {
    exists: true,
    startTime: 0,
    endTime: 300,
  },
  brunch: {
    exists: true,
    startTime: 300,
    endTime: 500,
  },
  lunch: {
    exists: true,
    startTime: 500,
    endTime: 900,
  },
  dinner: {
    exists: true,
    startTime: 900,
    endTime: 1300,
  },
  nightTime: {
    exists: true,
    startTime: 1300,
    endTime: 1440,
  },
}
export function ShiftReportingPeriods() {
  const { venueId } = useVenueContext()
  const { data: periods } = useGetVenueShiftReportingPeriodsQuery({ venueId })
  const { formatMessage } = useLocales()
  const [deleteShiftReportingPeriod, setDeleteShiftReportingPeriod] = useState<number | null>(null)
  const defaultValues = useMemo(
    () => ({
      shiftReportingPeriods:
        periods?.map(period => ({
          name: period.name,
          isDefault: period.isDefault,
          breakfast: {
            exists: period.breakfastExists,
            startTime: period.breakfastStartTime,
            endTime: period.breakfastEndTime,
          },
          brunch: {
            exists: period.brunchExists,
            startTime: period.brunchStartTime,
            endTime: period.brunchEndTime,
          },
          lunch: {
            exists: period.lunchExists,
            startTime: period.lunchStartTime,
            endTime: period.lunchEndTime,
          },
          dinner: {
            exists: period.dinnerExists,
            startTime: period.dinnerStartTime,
            endTime: period.dinnerEndTime,
          },
          nightTime: {
            exists: period.nightTimeExists,
            startTime: period.nightTimeStartTime,
            endTime: period.nightTimeEndTime,
          },
        })) || [],
    }),
    [periods]
  )
  const form = useForm(useShiftReportPeriodsSettingsSchema(), { defaultValues })
  const { field } = form
  const {
    fields: reportingPeriods,
    append: appendReportingPeriod,
    remove: removeReportingPeriod,
  } = useFieldArray(field, 'shiftReportingPeriods')
  const [updateVenueShiftReportingPeriods, { isLoading }] = useUpdateVenueShiftReportingPeriodsMutation()
  const submitReportingPeriods = async (formData: FormValues) => {
    await updateVenueShiftReportingPeriods({
      shiftReportingPeriods: formData.shiftReportingPeriods.map(period => ({
        name: period.name,
        isDefault: period.isDefault,
        breakfastExists: period.breakfast.exists || false,
        breakfastStartTime: period.breakfast.startTime === null ? undefined : period.breakfast.startTime,
        breakfastEndTime: period.breakfast.endTime === null ? undefined : period.breakfast.endTime,
        brunchExists: period.brunch.exists || false,
        brunchStartTime: period.brunch.startTime === null ? undefined : period.brunch.startTime,
        brunchEndTime: period.brunch.endTime === null ? undefined : period.brunch.endTime,
        lunchExists: period.lunch.exists || false,
        lunchStartTime: period.lunch.startTime === null ? undefined : period.lunch.startTime,
        lunchEndTime: period.lunch.endTime === null ? undefined : period.lunch.endTime,
        dinnerExists: period.dinner.exists || false,
        dinnerStartTime: period.dinner.startTime === null ? undefined : period.dinner.startTime,
        dinnerEndTime: period.dinner.endTime === null ? undefined : period.dinner.endTime,
        nightTimeExists: period.nightTime.exists || false,
        nightTimeStartTime: period.nightTime.startTime === null ? undefined : period.nightTime.startTime,
        nightTimeEndTime: period.nightTime.endTime === null ? undefined : period.nightTime.endTime,
      })),
      venueId,
    })
      .then(() => notify({ content: formatMessage(ShriftReportingPeriodSettingsMessages.success), type: 'success' }))
      .catch(() => notify({ content: formatMessage(ShriftReportingPeriodSettingsMessages.error), type: 'error' }))
  }
  return isLoading ? (
    <Loader />
  ) : (
    <>
      <SettingsPageMeta title={formatMessage(ShriftReportingPeriodSettingsMessages.shiftReportingTitle)} />
      <Form {...form} onSubmit={submitReportingPeriods} onInvalid={() => {}}>
        <SettingsPageContent
          title={formatMessage(ShriftReportingPeriodSettingsMessages.shiftReportingTitle)}
          description={formatMessage(ShriftReportingPeriodSettingsMessages.shiftReportingDescription)}
          headerWidth="calc(100% - 274px)"
          secondHeaderMaxWidth="968px"
          actions={
            <HStack spacing="sm">
              <Button
                variant="secondary"
                icon="VMSWeb-add"
                onClick={() => {
                  appendReportingPeriod(DEFAULT_REPORTING_PERIOD)
                }}
                id="add-reporting-periods"
                data-test="add-reporting-periods"
              >
                {formatMessage(ShriftReportingPeriodSettingsMessages.addReportingPeriod)}
              </Button>
              <Button data-test="save-reporing-periods" type="submit">
                {formatMessage(commonMessages.saveChanges)}
              </Button>
            </HStack>
          }
        >
          <VStack spacing="lm" ml="lm" mt="s" mb="xxl">
            {reportingPeriods.map((reportingPeriod, index) => (
              <BaseSection key={reportingPeriod.id}>
                <VStack p="l" borderWidth="s">
                  <HStack>
                    <FormInput
                      field={field.prop(`shiftReportingPeriods.${index}.name`)}
                      placeholder={formatMessage(ShriftReportingPeriodSettingsMessages.enterReportingPeriodsName)}
                    />
                    {!reportingPeriod.isDefault && (
                      <Button
                        data-test="delete-reporting-period-button"
                        onClick={() => setDeleteShiftReportingPeriod(index)}
                        variant="tertiary-warning"
                        size="s"
                      >
                        {formatMessage(commonMessages.delete)}
                      </Button>
                    )}
                  </HStack>
                  <MealRangeSelector field={field.prop(`shiftReportingPeriods.${index}`)} />
                </VStack>
              </BaseSection>
            ))}
          </VStack>
        </SettingsPageContent>
      </Form>
      <Window active={deleteShiftReportingPeriod !== null}>
        {deleteShiftReportingPeriod && (
          <DeleteShiftPeriod
            onDiscard={() => setDeleteShiftReportingPeriod(null)}
            onSubmit={() => {
              removeReportingPeriod(deleteShiftReportingPeriod)
              setDeleteShiftReportingPeriod(null)
            }}
            shiftReportingPeriodName={reportingPeriods[deleteShiftReportingPeriod]?.name || ''}
          />
        )}
      </Window>
    </>
  )
}
