import React, { type PropsWithChildren, useEffect, useMemo, useState } from 'react'
import type { TableItem } from '@sevenrooms/core/api'
import { ExperienceLinkTypeEnum, type SeatingAreaToTables } from '@sevenrooms/core/domain'
import type { UseForm, z, Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FormNumberInput, RadioGroup } from '@sevenrooms/core/ui-kit/form'
import { HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { SeatingAreasFields } from '@sevenrooms/mgr-access-rules-slideout/components/SeatingAreas/components'
import { Button } from '@sevenrooms/react-components/components/Button/index'
import { messages } from './locales/experienceStepperFlow.locales'
import { NewAccessRuleReview } from './NewAccessRuleReview'
import type { CreateExperienceFromTemplateFormData } from './CreateExperienceFromTemplateForm.zod'

export function NewAccessRuleCustomization({
  form,
  shiftSeatingAreas,
  setFlowCompleted,
  isAccessRuleCustomizationInitialPart,
  setIsAccessRuleCustomizationInitialPart,
  setFlowStep,
  setUseSaveButton,
}: {
  form: UseForm<z.ZodType<CreateExperienceFromTemplateFormData>>
  shiftSeatingAreas: { id: string; name: string; seatingAreas: SeatingAreaToTables[]; tables: TableItem[] }[]
  setFlowCompleted: (isCompleted: boolean) => void
  isAccessRuleCustomizationInitialPart: boolean
  setIsAccessRuleCustomizationInitialPart: (isInitial: boolean) => void
  setFlowStep: (step: number | undefined) => void
  setUseSaveButton: (useSaveButton: boolean) => void
}) {
  useEffect(() => {
    if (isAccessRuleCustomizationInitialPart) {
      setFlowCompleted(false)
    } else {
      setFlowCompleted(true)
    }
  }, [isAccessRuleCustomizationInitialPart, setFlowCompleted])

  return isAccessRuleCustomizationInitialPart ? (
    <AccessRuleCustomization form={form} shiftSeatingAreas={shiftSeatingAreas} />
  ) : (
    <NewAccessRuleReview
      form={form}
      setFlowStep={setFlowStep}
      setIsAccessRuleCustomizationInitialPart={setIsAccessRuleCustomizationInitialPart}
      setUseSaveButton={setUseSaveButton}
    />
  )
}

function AccessRuleCustomization({
  form,
  shiftSeatingAreas,
}: {
  form: UseForm<z.ZodType<CreateExperienceFromTemplateFormData>>
  shiftSeatingAreas: { id: string; name: string; seatingAreas: SeatingAreaToTables[]; tables: TableItem[] }[]
}) {
  const { formatMessage } = useLocales()
  const { field, getValues } = form
  const values = getValues()

  const [showPartySizeInputs, setShowPartySizeInputs] = useState(false)

  return (
    <VStack pt="xxl" pb="xxl" spacing="l" width="496px" data-test="create-new-access-rule-customization-step-content">
      <VStack spacing="xs" alignItems="center">
        <Text textStyle="h2" textAlign="center">
          {formatMessage(messages.newAccessRuleCustomizationStepTitle, {
            offerName: (
              <Text textStyle="h2" fontWeight="bold">
                "{values.name}"
              </Text>
            ),
          })}
        </Text>
        <Text textStyle="body1" color="secondaryFont">
          {formatMessage(messages.newAccessRuleCustomizationStepDescription)}
        </Text>
      </VStack>
      <CustomizationField label={formatMessage(messages.newAccessRuleCustomizationOfferType)}>
        <CustomizationOfferTypeOptions field={field.prop('accessType')} />
      </CustomizationField>
      <VStack>
        <CustomizationFieldWrapper noBottomBorderRadius={showPartySizeInputs}>
          <HStack justifyContent="space-between" alignItems="center">
            <Text textStyle="body1Bold">{formatMessage(messages.newAccessRuleCustomizationPartySize)}</Text>
            <Button
              data-test="expand-party-size-fields-btn"
              variant="outlined"
              size="medium"
              sx={{ width: '73px' }}
              onClick={() => setShowPartySizeInputs(!showPartySizeInputs)}
            >
              {formatMessage(messages.addButton)}
            </Button>
          </HStack>
        </CustomizationFieldWrapper>
        {showPartySizeInputs && (
          <CustomizationAdditionalSection label={formatMessage(messages.newAccessRuleCustomizationPartySizeExpanded)}>
            <HStack spacing="s">
              <VStack spacing="xs">
                <Text textStyle="body1">{formatMessage(messages.newAccessRuleCustomizationPartySizeMin)}</Text>
                <FormNumberInput field={field.prop('accessRule').prop('partySize.min')} decimalScale={0} />
              </VStack>
              <VStack spacing="xs">
                <Text textStyle="body1">{formatMessage(messages.newAccessRuleCustomizationPartySizeMax)}</Text>
                <FormNumberInput field={field.prop('accessRule').prop('partySize.max')} decimalScale={0} />
              </VStack>
            </HStack>
          </CustomizationAdditionalSection>
        )}
      </VStack>
      <CustomizationField label={formatMessage(messages.newAccessRuleCustomizationSeatingAreas)}>
        <SeatingAreasFields
          field={field.prop('accessRule').prop('seatingAreasAndTableCombinations')}
          shiftSeatingAreas={shiftSeatingAreas}
          conflict={<></>}
          showRestrictionCheckbox={false}
        />
      </CustomizationField>
    </VStack>
  )
}

function CustomizationOfferTypeOptions({ field }: { field: Field<number> }) {
  const { formatMessage } = useLocales()
  const choices = useMemo(
    () => [
      {
        value: ExperienceLinkTypeEnum.OPEN_ACCESS,
        label: <Text textStyle="body1">{formatMessage(messages.newAccessRuleCustomizationOfferTypePublic)}</Text>,
      },
      {
        value: ExperienceLinkTypeEnum.EXCLUSIVE_ACCESS,
        label: <Text textStyle="body1">{formatMessage(messages.newAccessRuleCustomizationOfferTypeExclusive)}</Text>,
      },
    ],
    [formatMessage]
  )
  return <RadioGroup data-test="offer-access-type-selector" choices={choices} field={field} />
}

function CustomizationFieldWrapper({ children, noBottomBorderRadius }: PropsWithChildren<{ noBottomBorderRadius?: boolean }>) {
  return (
    <VStack
      spacing="s"
      borderWidth="s"
      borderBottomLeftRadius={noBottomBorderRadius ? 'none' : 's'}
      borderBottomRightRadius={noBottomBorderRadius ? 'none' : 's'}
      borderTopLeftRadius="s"
      borderTopRightRadius="s"
      borderColor="dividerLines"
      p="m"
    >
      {children}
    </VStack>
  )
}

function CustomizationAdditionalSection({ label, children }: PropsWithChildren<{ label: string }>) {
  return (
    <VStack
      spacing="s"
      borderWidth="s"
      borderBottomLeftRadius="s"
      borderBottomRightRadius="s"
      borderColor="dividerLines"
      backgroundColor="secondaryBackground"
      p="m"
    >
      <Text textStyle="body1Bold">{label}</Text>
      {children}
    </VStack>
  )
}

function CustomizationField({ label, children }: PropsWithChildren<{ label: string }>) {
  return (
    <CustomizationFieldWrapper>
      <Text textStyle="body1Bold">{label}</Text>
      {children}
    </CustomizationFieldWrapper>
  )
}
