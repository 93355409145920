import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  nextButtonText: {
    id: 'stepperFlow.nextButtonText',
    defaultMessage: 'Next',
  },
  backButtonText: {
    id: 'stepperFlow.backButtonText',
    defaultMessage: 'Back',
  },
  finishButtonText: {
    id: 'stepperFlow.finishButtonText',
    defaultMessage: 'Finish',
  },
  cancelButtonText: {
    id: 'stepperFlow.cancelButtonText',
    defaultMessage: 'Cancel',
  },
  saveButtonText: {
    id: 'stepperFlow.saveButtonText',
    defaultMessage: 'Save',
  },
})
