import React, { type PropsWithChildren } from 'react'
import type { Field, UseForm, z } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { RecommendationTip } from '@sevenrooms/core/ui-kit/core'
import { FormInput, ImageUploader } from '@sevenrooms/core/ui-kit/form'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { defaultConfig, FormEditor } from '@sevenrooms/core/ui-kit/optional'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import TimeSlotPreviewImage from './assets/images/time-slot-preview.svg'
import { messages } from './locales/experienceStepperFlow.locales'
import type { CreateExperienceFromTemplateFormData } from './CreateExperienceFromTemplateForm.zod'

export function OfferDetails({
  form,
  setShowNextButton,
}: {
  form: UseForm<z.ZodType<CreateExperienceFromTemplateFormData>>
  setShowNextButton: (show: boolean) => void
}) {
  setShowNextButton(true)
  const { formatMessage } = useLocales()
  const { field } = form
  const heroImageAspectRatio = 2.25

  return (
    <VStack pt="xxl" pb="xxl" spacing="l" width="496px" data-test="offer-details-step-content">
      <VStack spacing="xs" alignItems="center">
        <Text textStyle="h2">{formatMessage(messages.offerDetailsStepTitle)}</Text>
        <Text textStyle="body1" color="secondaryFont">
          {formatMessage(messages.offerDetailsStepDescription)}
        </Text>
      </VStack>
      <VStack>
        <OfferDetailsFormInput label={formatMessage(messages.offerTitle)} field={field.prop('name')} placeholder="" noBottomBorderRadius />
        <RecommendationTip
          header={formatMessage(messages.recommendationTipBestPractice)}
          description={formatMessage(messages.offerTitleRecommendation)}
          headerIcon="bestPractice"
          noTopBorderRadius
        />
      </VStack>
      <OfferDetailsFormEditor label={formatMessage(messages.offerDescription)} field={field.prop('description')} />
      <VStack>
        <OfferDetailsFormInput
          label={formatMessage(messages.offerPreviewDescription)}
          field={field.prop('accessRule.guestFacing.timeslotDescription')}
          placeholder={formatMessage(messages.offerPreviewDescriptionPlaceholder)}
          noBottomBorderRadius
        />
        <RecommendationTip
          header={formatMessage(messages.recommendationTipBestPractice)}
          description={formatMessage(messages.offerPreviewDescriptionRecommendation)}
          headerIcon="bestPractice"
          imageSrc={TimeSlotPreviewImage}
          noTopBorderRadius
        />
      </VStack>
      <OfferDetailsFormInput label={formatMessage(messages.offerPrice)} field={field.prop('price')} placeholder="" />
      <VStack borderWidth="s" borderRadius="s" borderColor="dividerLines" p="m">
        <Text textStyle="body1Bold">{formatMessage(messages.offerImage)}</Text>
        <ImageUploader
          field={field.prop('heroImage')}
          aspect={heroImageAspectRatio}
          unit="%"
          data-test="offer-details-image-uploader"
          fullWidth
        />
      </VStack>
    </VStack>
  )
}

interface OfferDetailsFieldWrapperProps {
  label: string
  // eslint-disable-next-line react/no-unused-prop-types
  noBottomBorderRadius?: boolean
}
interface OfferDetailsFormEditorProps extends OfferDetailsFieldWrapperProps {
  field: Field<string>
}
interface OfferDetailsFormInputProps extends OfferDetailsFormEditorProps {
  placeholder: string
}

function OfferDetailsFieldWrapper({ label, noBottomBorderRadius, children }: PropsWithChildren<OfferDetailsFieldWrapperProps>) {
  return (
    <VStack
      spacing="s"
      borderWidth="s"
      borderBottomLeftRadius={noBottomBorderRadius ? 'none' : 's'}
      borderBottomRightRadius={noBottomBorderRadius ? 'none' : 's'}
      borderTopLeftRadius="s"
      borderTopRightRadius="s"
      borderColor="dividerLines"
      p="m"
    >
      <Text textStyle="body1Bold">{label}</Text>
      {children}
    </VStack>
  )
}

function OfferDetailsFormInput({ label, field, placeholder, noBottomBorderRadius }: OfferDetailsFormInputProps) {
  return (
    <OfferDetailsFieldWrapper label={label} noBottomBorderRadius={noBottomBorderRadius}>
      <FormInput field={field} placeholder={placeholder} fullWidth />
    </OfferDetailsFieldWrapper>
  )
}

function OfferDetailsFormEditor({ label, field }: OfferDetailsFormEditorProps) {
  return (
    <OfferDetailsFieldWrapper label={label}>
      <FormEditor
        field={field}
        config={{
          ...defaultConfig,
          heightMin: 100,
          toolbarButtons: [
            'textColor',
            'bold',
            'italic',
            'underline',
            'alignLeft',
            'alignCenter',
            'alignRight',
            'formatUL',
            'formatOL',
            'insertLink',
          ],
          toolbarBottom: false,
          useClasses: false,
        }}
        data-test="offer-details-freola-input"
      />
    </OfferDetailsFieldWrapper>
  )
}
